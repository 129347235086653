import { size } from "lodash-es";
import { apiCall, apiCallParams, logResponse } from "@/core/api";

export async function getFlowLogList(): Promise<any> {
  const resource = "/flow_logs";
  const response = await apiCall("GET", resource);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function getFlowLogOrganizationSpecificList(params): Promise<any> {
  const resource = "/flow_logs/all";
  const response = await apiCallParams("GET", resource, params);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function getRunningFlowLogsCount(): Promise<any> {
  const resource = "/flow_logs/running";
  const response = await apiCall("GET", resource);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function getRecentLogsOrganizationSpecificList(): Promise<any> {
  const params = {
    page: 0,
    size: 8,
    search: null,
  };
  const resource = "/flow_logs/all";
  const response = await apiCallParams("GET", resource, params);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function getStepsTimeStats(): Promise<any> {
  const resource = "/flow_logs/steps_time_stats";
  const response = await apiCall("GET", resource);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function deleteFlowLog(logId: string): Promise<boolean> {
  const resource = `/flow_logs/${logId}`;
  const response = await apiCall("DELETE", resource);
  if (response.code === 200) {
    return true;
  }
  logResponse(response);
  return false;
}

export async function getFlowLogAggregation(params: any): Promise<any> {
  const resource = "/flow_logs/aggregation";
  const response = await apiCallParams("GET", resource, params);
  if (response.code === 200) {
    return response.result[0];
  }
  logResponse(response);
  return false;
}

export async function getFlowLogsFromQuery(queryDto: any): Promise<any> {
  const resource = "/flow_logs/query";
  const response = await apiCall("POST", resource, queryDto);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return false;
}

export async function getFlowLogsFromElasticSearch(params): Promise<any> {
  const resource = "/activities/listing";
  const response = await apiCallParams("GET", resource, params);
  if (response.code === 200) {
    return response.page;
  }
  logResponse(response);
  return false;
}

export async function getActivityLogForFlow(activityId: any): Promise<any> {
  const resource = `/activities/logs/${activityId}`;
  const response = await apiCall("GET", resource);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return false;
}

export async function getFlowLogById(flowId: string, queryDto: any): Promise<any> {
  const resource = `/flow_logs/flow/${flowId}`;
  const response = await apiCall("POST", resource, queryDto);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return false;
}

export async function getSpecificIndexOfStepLog(flowLogId:string, stepId: string, splitIx: number): Promise<any> {
  const endpoint = `/activities/split/${flowLogId}/${stepId}/${splitIx}`;
  const response = await apiCall("GET", endpoint);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return null;
}
export async function getFlowLogByActivityId(activityId:string) {
  const endpoint = `/activities/logs/${activityId}`;
  const response = await apiCall("GET", endpoint);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return null;
}
export async function getAllFlowLogByActivityId(activityId:string) {
  const endpoint = `/activities/all-logs/${activityId}`;
  const response = await apiCall("GET", endpoint);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return null;
}
